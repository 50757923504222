import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="bp">
            <div className="wrapper bp">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="section">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="top">
                <h2>Péče o pleť</h2>
                <div className="breadcrumb">
                  <a href="/">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>&gt;</span>
                  <a href="/budte-krasna/">Buďte krásná</a> <span>&gt;</span>
                  Péče o pleť
                </div>
              </div>
            </div>
          </div>
          <div className="submenu bpmenu">
            <div className="wrapper">
              <a href="/budte-krasna/sucha-pokozka/" className="link">
                Suchá pokožka
              </a>
              <a href="/budte-krasna/pece-o-plet/" className="current">
                Péče o pleť
              </a>
              <a href="/budte-krasna/pece-o-rty/" className="link">
                Péče o rty
              </a>
              <a href="/budte-krasna/pece-o-ruce/" className="link">
                Péče o ruce
              </a>
              <a href="/budte-krasna/pece-o-nohy/" className="link">
                Péče o nohy
              </a>
              <a href="/budte-krasna/pece-o-tlo/" className="link">
                Péče o tělo
              </a>
            </div>
          </div>
          <div className="wrapper">
            <div className="content">
              <a name="aboutAnchor" />
              <section className="first">
                <div className="bg twarz1">
                  <div className="text full">
                    <h2>Pokožka (obecné informace)</h2>
                    <p className="text left">
                      Na naši pokožku působí více vlivů, než si myslíme. Proto
                      je velice důležité poskytovat naší pokožce náležitou péči,
                      která ji ochrání před mimořádnou zátěží. Proti některým
                      faktorům, které jsou pro kůži škodlivé, můžeme těžko něco
                      udělat (např. UV záření či chladný a suchý vzduch), ale
                      jsou i jiné, proti kterým můžeme bojovat. Abychom toho
                      byli schopni, měli bychom:
                    </p>
                    <p className="text left">&nbsp;</p>
                    <ul className="text left">
                      <li>dbát na to, abychom jedli zdravě</li>
                      <li>vyhýbat se nikotinu</li>
                      <li>
                        vyhýbat se přímému styku kůže s čisticími prostředky
                      </li>
                      <li>dělat vše proto, abychom měli dostatek spánku</li>
                      <li>pravidelně se věnovat pohybu na čerstvém vzduchu</li>
                      <li>
                        dostatečně pít, alespoň 2 litry denně – především vodu,
                        bylinné čaje či džusy <br />s vodou
                      </li>
                      <li>
                        během dne si dopřát čas na odpočinek a relaxaci, jako
                        např. rychlou masáž
                      </li>
                      <li>
                        vyhýbat se nadměrnému vystavování kůže UV paprskům a
                        příliš častým návštěvám solária
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                <div className="text full">
                  <h2>Péče o tělo a obličej – tipy pro krásu</h2>
                  <div className="column left">
                    <p>
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                      >
                        1.{" "}
                      </span>
                      Každý den či několikrát týdně používejte tělové mléko
                      vhodné pro Váš typ pokožky. Je zvláště důležité pokožku
                      hydratovat okamžitě po koupeli či opalování. Oblasti
                      pokožky, které jsou nejvíce vystaveny externím faktorům
                      musí být ošetřeny výrobky, které jsou bohaté na různé
                      složky.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                      >
                        2.
                      </span>{" "}
                      Výrobky používané při každodenní hygieně by měly mít
                      hodnotu pH, která nepoškozuje přirozenou ochrannou vrstvu
                      pokožky.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                      >
                        3.
                      </span>{" "}
                      Voda používaná při koupeli či sprchování by neměla být
                      příliš horká. Pokud je pro vás těžké vzdát se v zimě horké
                      koupele, přidejte si do lázně olivový olej či jiný výrobek
                      s obsahem oleje. Olej vytvoří na pokožce tenký ochranný
                      film.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                      >
                        4.
                      </span>{" "}
                      Každý den byste měla vypít okolo 1,5 – 2 litrů vody,
                      ovocných či bylinných čajů. Tak podpoříte správné
                      fungování pokožky.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                      >
                        5.
                      </span>{" "}
                      Naše strava by měla být dobře vyvážená a bohatá na
                      vitamíny. Je doporučeno jíst ryby, celozrnné cereálie,
                      zeleninu a ovoce, <br />a měla byste tělu zajistit
                      dostatečný přísun důležitých minerálů, jako např. železo,
                      horčík, draslík a zinek.
                    </p>
                  </div>
                  <div className="column right">
                    <p>
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                        data-mce-mark="1"
                      >
                        6.
                      </span>{" "}
                      Nezůstávejte na slunci bez náležité ochrany; rovněž byste
                      se měla vyhnout častému a dlouhému slunění a častým
                      návštěvám solária.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                        data-mce-mark="1"
                      >
                        7.
                      </span>{" "}
                      Je doporučeno pravidelně cvičit, věnovat se sportům či
                      chození na procházky. Cvičení má pozitivní vliv na
                      prokrvení pokožky a vylučování potu detoxikuje tělo.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                        data-mce-mark="1"
                      >
                        8.
                      </span>{" "}
                      Když se věnujete domácím pracem, které vyžadují používání
                      čistících prostředků a detergentů, noste gumové rukavice,
                      abyste ochránila pokožku před vysušením.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                        data-mce-mark="1"
                      >
                        9.
                      </span>{" "}
                      Nové oblečení před prvním nošením vyperte, abyste ho
                      zbavila zbytků chemikálií.
                      <br />
                      <span
                        style={{
                          color: "#ff0000"
                        }}
                        data-mce-mark="1"
                      >
                        10.
                      </span>{" "}
                      Nikotin a alkohol jsou pro pokožku škodlivé a urychlují
                      proces stárnutí. Kvůli své pokožce omezte kouření a
                      konzumaci alkoholu.
                    </p>
                  </div>
                </div>
              </section>
              <section className="last">
                <div className="text full">
                  <div className="bg twarz2">
                    <div className="p">
                      <h2>Tipy na péči o obličej – čistá pleť</h2>
                      <div className="bg twarz2">
                        <div className="p">
                          <div className="column left">
                            <span className="red" data-mce-mark="1">
                              <span className="red" data-mce-mark="1">
                                1.
                              </span>
                              &nbsp;Pravidelné čistění pleti
                            </span>
                            <br />
                            Čistěte obličej ráno i večer pomocí přípravku
                            vhodného pro Váš typ pleti, následně jej opatrně
                            opláchněte vodou. Osuště obličej čistým
                            ručníkem.&nbsp;
                            <br />
                            <span className="red" data-mce-mark="1">
                              <span className="red" data-mce-mark="1">
                                2.
                              </span>
                              &nbsp;Obličej by neměl být myt příliš často.
                            </span>
                            <br />
                            Stačí ráno a večer. Příliš časté mytí může narušit
                            přirozený ochranný film.
                            <br />
                            <span className="red" data-mce-mark="1">
                              <span className="red" data-mce-mark="1">
                                3.
                              </span>
                              &nbsp;Vhodný make-up
                            </span>
                            <br />
                            Pokud máte mastnou či kombinovanou pokožku,
                            používejte nemastnou kosmetiku či pudr.
                            Nezapomínejte make-up večer odstranit.
                            <br />
                            <span className="red" data-mce-mark="1">
                              <span className="red" data-mce-mark="1">
                                4.
                              </span>
                              &nbsp;Péče o pleť
                            </span>
                            <br />
                            Každý typ pleti vyžaduje hydrataci. Proto je
                            nezbytné, abyste jí poskytla náležitou péči s
                            použitím výrobků vhodných pro Váš typ pleti. Výrobek
                            pro mastnou a kombinovanou pleť obsahuje aktivní
                            látky chránící pokožku před nečistotami, a rovněž
                            látky, které pokožku jemně hydratují.&nbsp;
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                      <div className="bg twarz3">
                        <div className="p">
                          <div className="column right wider">
                            <span className="red">
                              <span className="red">5.</span>&nbsp;Pečlivě si
                              přečtěte etiketu
                            </span>
                            <br />
                            Nezapomínejte číst etikety a vybírejte výrobky
                            vhodné pro Váš typ pleti. Dokonale vybraný výrobek
                            neucpává póry a chrání pleť před nečistotami
                            <span>, tedy i před tvorbou černých teček.</span>
                            <br />
                            <span className="red">
                              <span className="red">6.</span>&nbsp;Neškrábat a
                              nemačkat
                            </span>
                            <br />
                            Škrábání a mačkání pouze zhoršuje zanícení, případně
                            pleť ještě více dráždí. Rovněž může způsobit
                            infekci, či může vést ke vzniku jizev. Na „pupínky”
                            používejte výrobky speciálně vyvinuté na lokální
                            použití.
                            <br />
                            <span className="red">
                              <span className="red">
                                7. Nedotýkejte se rukama
                              </span>
                            </span>
                            <br />
                            Na Vašich rukách je vždy mnoho bakterií. Nedotýkejte
                            se rukama svého obličeje, aby se na něj nedostaly
                            další bakterie, které by způsobily další kontaminaci
                            pleti.
                            <br />
                            <span className="red">
                              <span className="red">8.</span>&nbsp;Věnujte
                              pozornost svým vlasům
                            </span>
                            <br />
                            Nenechte vlasy padat do tváře – tak zamezíte většímu
                            maštění pleti. Pokud nosíte ofinu, stačí si vlasy
                            pravidelně mýt.
                            <br />
                            <span className="red">
                              <span className="red">9.</span>&nbsp;Zdravý
                              životní styl
                            </span>
                            <br />
                            Zdravá strava, vyhýbání se nikotinu, dostatek spánku
                            a pravidelný pohyb chrání pleť před nadměrným
                            stresem.
                            <br />
                            <span className="red">
                              <span className="red">10.</span>&nbsp;Návštěva
                              dermatologa
                            </span>
                            <br />
                            Pokud se stav Vaší pleti nelepší, je doporučeno
                            vyhledat radu dermatologa.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div id="fb_73"></div>
            </div>
          </div>
          <div className="bluebar">
            <div className="bar">
              <div className="wrapper">
                <a href="/neutrogena/o-nas/">
                  O ZNAČCE NEUTROGENA<sup>®</sup>
                </a>
                <span>|</span>
                <a href="/neutrogena/norska-receptura/">Norská receptura</a>
                <span>|</span>
                <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
                <span>|</span>
                <a href="/produkty/">
                  Zobrazit produkty NEUTROGENA<sup>®</sup>
                </a>
              </div>
            </div>
          </div>
          <Footer></Footer>
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
            }}
          />
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
